import { Tab, TabList, Heading } from '@chakra-ui/react'
import styles from './GamesContainer.module.css'

interface GamesTabMenuProps {
  assumedUserName?: string
  isMobile: boolean
}

export const GamesTabMenu = ({
  assumedUserName,
  isMobile,
}: GamesTabMenuProps) => {
  return (
    <div className={styles.tabsMenuContainer}>
      <TabList style={{ display: 'flex', gap: '8px' }}>
        <Tab>
          <Heading as="h2" fontSize={isMobile ? 'md' : 'xl'}>
            {assumedUserName ? `${assumedUserName}'s games` : 'My Games'}
          </Heading>
        </Tab>
        <Tab>
          <Heading as="h2" fontSize={isMobile ? 'md' : 'xl'}>
            Managed Games
          </Heading>
        </Tab>
      </TabList>
    </div>
  )
}
