import { GamesList } from './GamesList'
import { useRoles, userRoleIsAtLeast } from 'hooks'
import { Heading, Tabs, TabPanels, TabPanel } from '@chakra-ui/react'
import { GamesTabMenu } from './GamesTabMenu'
import { useFeatures } from 'hooks'
import { Feature } from 'types'

interface GamesContainerProps {
  activeFilter: string
  assumedUserName?: string
  currentPage: number
  games: any[]
  isMobile: boolean
  isPending?: boolean
  managedGames: any[]
  numberOfRows: number
  onGameClick?: (id: number) => void
  onPageChange: (pageNumber: number) => void
  onWinningsClick?: (event: any) => void
  totalEntries: number
}

export const GamesContainer = ({
  activeFilter,
  assumedUserName,
  currentPage,
  games,
  isMobile,
  isPending,
  managedGames,
  numberOfRows,
  onGameClick,
  onPageChange,
  onWinningsClick,
  totalEntries,
}: GamesContainerProps) => {
  const userRoles = useRoles()
  const atLeastTeamManager = userRoleIsAtLeast({
    userRole: userRoles,
    requiredRole: 'team_manager',
  })
  const hasGameCenterEnhancements = useFeatures(Feature.GameCenterEnhancements)

  const commonProps = {
    activeFilter,
    currentPage,
    isMobile,
    isPending,
    numberOfRows,
    onGameClick,
    onPageChange,
    onWinningsClick,
    totalEntries,
  }

  if (!atLeastTeamManager || !hasGameCenterEnhancements) {
    return (
      <>
        <Heading
          as="h2"
          color="text.primaryWhite"
          fontSize="2xl"
          marginBottom={8}
          marginTop={8}
          paddingX={{ base: 0, lg: 2, xl: 6 }}
        >
          My Games
        </Heading>
        <GamesList {...commonProps} games={games} managerView={false} />
      </>
    )
  }

  return (
    <Tabs variant="soft-rounded">
      <GamesTabMenu assumedUserName={assumedUserName} isMobile={isMobile} />
      <TabPanels>
        <TabPanel>
          <GamesList {...commonProps} games={games} managerView={false} />
        </TabPanel>
        <TabPanel>
          <GamesList {...commonProps} games={managedGames} managerView={true} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
