import { FC, useEffect, useContext } from 'react'

import { AppContext } from '../../providers/MPAppContextProvider'
import { PageTitle } from '../../types'
import { GamesPageContainer } from '../../containers/GamesPageContainer'

const Games: FC = () => {
  const { setTitle } = useContext(AppContext)

  useEffect(() => {
    setTitle(`${PageTitle.Arcade} - Game Center`)
  }, [])

  return <GamesPageContainer />
}

export default Games
