import { Box, Flex } from '@chakra-ui/react'
import { CbGameCardOld } from 'ui/components/CbGameCard'
import { CbTablePagination } from 'ui/components/CbTable/CbTablePagination'
import { upFirst } from 'ui/helpers'
import { GamesEmptyState } from '../../GamesEmptyState'
import { StatusType } from 'types'
import { CbGameCardSkeletonOld } from 'ui/components/CbGameCard/CbGameCardSkeletonOld'
import { useContext } from 'react'
import { AuthContext } from '../../../providers'
import { NormalizedGame } from 'containers/GamesPageContainer/normalizers'

interface GamesListProps {
  activeFilter: string
  currentPage: number
  games: NormalizedGame[]
  isMobile: boolean | undefined
  isPending: boolean | undefined
  managerView: boolean | undefined
  numberOfRows: number
  onGameClick: ((id: number) => void) | undefined
  onPageChange: (pageNumber: number) => void
  onWinningsClick: ((event: any) => void) | undefined
  totalEntries: number
}

export const GamesList = ({
  activeFilter,
  currentPage,
  games,
  isMobile,
  isPending,
  managerView = false,
  numberOfRows,
  onGameClick,
  onPageChange,
  onWinningsClick,
  totalEntries,
}: GamesListProps) => {
  const { auth } = useContext(AuthContext)

  const ConditionalWrapper: any = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  return (
    <Box paddingX={{ base: 4, xl: 0 }}>
      <Flex justifyContent="space-evenly" marginTop={2}>
        <Box width={{ base: '100%' }}>
          <ConditionalWrapper
            condition={isPending}
            wrapper={() => (
              <>
                <CbGameCardSkeletonOld key={0} marginBottom={4} />
                <CbGameCardSkeletonOld key={1} marginBottom={4} />
                <CbGameCardSkeletonOld key={2} marginBottom={4} />{' '}
              </>
            )}
          >
            {games.length > 0 ? (
              <>
                {games.map(game => (
                  <CbGameCardOld
                    coverImageUrl={game.coverImageUrl}
                    endDateTime={game.endDateTime}
                    gameType={game.gameType}
                    goal={game.goal}
                    hasGameButton={game.status !== StatusType.Upcoming}
                    hasGameClick={game.status !== StatusType.Upcoming}
                    id={game.id}
                    isCompleted={game.status === StatusType.Completed}
                    isCurrentUser={game.userId === auth.id}
                    isUpcoming={game.status === StatusType.Upcoming}
                    key={game.id}
                    managerView={managerView}
                    marginBottom={6}
                    metric={game.metric}
                    name={game.name}
                    numberOfWinners={game.rewards?.length}
                    onGameClick={onGameClick}
                    onWinningsClick={onWinningsClick}
                    place={game.place}
                    playerCount={game?.playerCount}
                    prizeAmount={game.prizeAmount}
                    prizeType={game.prizeType}
                    rewards={game.rewards}
                    rewardsAwardedCount={game?.rewardsAwardedCount}
                    startDateTime={game.startDateTime}
                    totalScore={game.totalScore}
                    unit={game.unit}
                    userImageUrl={game.userImageUrl}
                    userName={game.userName}
                  />
                ))}
                <Box marginTop={8} marginBottom={12}>
                  <CbTablePagination
                    currentPage={currentPage}
                    handlePageChange={onPageChange}
                    isDarkMode
                    label={`${upFirst(activeFilter)} Games`}
                    numberOfRows={numberOfRows}
                    totalEntries={totalEntries}
                  />
                </Box>
              </>
            ) : (
              <GamesEmptyState
                activeFilter={activeFilter}
                isMobile={isMobile}
                managerView={managerView}
              />
            )}
          </ConditionalWrapper>
        </Box>
      </Flex>
    </Box>
  )
}
