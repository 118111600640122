import { FC } from 'react'

import { CbEmptySearchState } from 'ui/components/CbEmptySearchState'

import { GameFilters } from '../../types'

export interface GamesEmptyStateProps {
  activeFilter?: string
  isMobile?: boolean
  managerView?: boolean
}

export const GamesEmptyState: FC<GamesEmptyStateProps> = ({
  activeFilter,
  isMobile,
  managerView = false,
}) => {
  const subject = managerView ? 'Your managed players' : 'You'

  const getAlertByFilter = (): string => {
    switch (activeFilter) {
      case GameFilters.Active:
        return `${subject} have no active games at the moment.`
      case GameFilters.Completed:
        return `${subject} have not completed any games yet.`
      case GameFilters.Upcoming:
        return `${subject} have no upcoming games at the moment.`
      default:
        return `${subject} have no games at the moment.`
    }
  }

  const getSuggestionByFilter = (): string => {
    switch (activeFilter) {
      case GameFilters.Completed:
        return 'Or a game is currently in review.'
      default:
        return ''
    }
  }

  return (
    <CbEmptySearchState
      alert={getAlertByFilter()}
      suggestion={getSuggestionByFilter()}
      backgroundColor="darkMode.500"
      color="cloud.500"
      paddingX={6}
      paddingY={6}
      minHeight={isMobile ? '240px' : '360px'}
    />
  )
}
