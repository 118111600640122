import { Box, Flex, Heading } from '@chakra-ui/react'
import { JoinableGameCard } from './JoinableGameCard'
import { useFeatures } from 'hooks'
import { Feature } from 'types'
import { Joinable } from 'types/Joinable'

interface JoinableGameProps {
  joinableGames: Joinable[]
}

export const JoinableGames = ({ joinableGames }: JoinableGameProps) => {
  const hasGameGuides = useFeatures(Feature.GameGuides)

  if (!hasGameGuides || joinableGames.length === 0) {
    return <></>
  }

  return (
    <Box paddingX={{ base: 4, xl: 0 }}>
      <Flex justifyContent="space-evenly" marginTop={8}>
        <Box width={{ base: '100%' }}>
          <Heading
            as="h2"
            color="text.primaryWhite"
            fontSize="2xl"
            marginBottom={4}
            paddingX={{ base: 0, lg: 2, xl: 6 }}
          >
            Available Guides
          </Heading>
          <Flex direction={'column'} gap={4}>
            {joinableGames.map(game =>
              game.guides.map(guide => (
                <JoinableGameCard
                  key={guide.id}
                  guide={guide}
                  coverImageUrl={game.coverImageUrl}
                  name={game.name}
                  rewards={game.rewards}
                  type={game.type}
                />
              )),
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
