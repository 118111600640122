import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { get } from 'lodash'
import arcadeApiClient from '../../apiClients/arcadeApiClient'
import {
  Routes,
  gamesFilterOptionValues,
  GameResponse,
  GameFilters,
  SortDirection,
  Feature,
} from '../../types'
import { GamesPage } from '../../components/GamesPage'
import { normalizeGame } from './normalizers'
import { useFeatures, usePagination } from '../../hooks'
import { SpectatorViewContext } from '../../providers'

const fetcher = async (url: string): Promise<any> =>
  await arcadeApiClient.get(url).then(res => ({
    totalGames: Number(res.headers['x-total']),
    games: res.data.games.map((game: GameResponse) => normalizeGame(game)),
  }))

const joinableFetcher = async (url: string): Promise<any> =>
  await arcadeApiClient.get(url).then(res => ({
    games: res.data.games.map(game => ({
      guides: game.guides,
      coverImageUrl: game.cover_image_url,
      id: game.id,
      name: game.name,
      rewards: game.rewards,
      type: game.type,
    })),
  }))

export const GamesPageContainer: FC = () => {
  const [games, setGames] = useState<any>([])
  const [managedGames, setManagedGames] = useState<any>([])
  const [totalGames, setTotalGames] = useState<number>(0)
  const [activeFilter, setActiveFilter] = useState<GameFilters>(
    gamesFilterOptionValues[0],
  )
  const pagination = usePagination({ page: 0, size: 6 })
  const router = useRouter()
  const { query } = router
  const { assumedUser, assumedUserFilter } = useContext(SpectatorViewContext)
  const hasGameGuides = useFeatures(Feature.GameGuides)

  const { sortDirection, sortBy } = (() => {
    switch (activeFilter) {
      case GameFilters.Upcoming:
        return {
          sortBy: 'starts_at',
          sortDirection: SortDirection.Asc,
        }
      case GameFilters.Completed:
        return {
          sortBy: 'expires_at',
          sortDirection: SortDirection.Desc,
        }
      default:
        return {
          sortBy: 'expires_at',
          sortDirection: SortDirection.Asc,
        }
    }
  })()

  const { data, error } = useSWR(
    `/multi_platform/games?${
      pagination.asParamString
    }&status=${activeFilter}&sort_by=${sortBy}&sort_direction=${sortDirection}${
      assumedUserFilter && '&'.concat(assumedUserFilter)
    }`,
    fetcher,
  )

  const { data: managedData } = useSWR(
    `/multi_platform/games/managed?${
      pagination.asParamString
    }&status=${activeFilter}&sort_by=${sortBy}&sort_direction=${sortDirection}${
      assumedUserFilter && '&'.concat(assumedUserFilter)
    }`,
    fetcher,
  )

  const { data: joinableData } = useSWR(
    hasGameGuides ? '/multi_platform/games/joinable' : null,
    joinableFetcher,
  )

  useMemo(() => {
    if (data?.games) {
      setGames(data.games)
    }
    if (data?.totalGames) {
      setTotalGames(data.totalGames)
    }
  }, [data])

  useMemo(() => {
    if (managedData?.games) {
      setManagedGames(managedData.games)
    }
    if (managedData?.totalGames) {
      setTotalGames(managedData.totalGames)
    }
  }, [managedData])

  const isPending = !data && !error

  useEffect(() => {
    pagination.resetPage()

    const status = get(query, 'status', GameFilters.Active) as GameFilters

    setActiveFilter(status)
  }, [query])

  const handleFilterChange = async (value: string): Promise<boolean> =>
    await router.push({
      pathname: router.pathname,
      query: { status: value },
    })

  const handleGameClick = async (id: number): Promise<boolean> =>
    await router.push(`${Routes.Games}/${id}`)

  const handlePageChange = async (page: number): Promise<void> => {
    window.scrollTo(0, 0)
    pagination.setPage(page)
  }

  const handleWinningsClick = async (): Promise<void> => {
    await router.push({
      pathname: Routes.Rewards,
    })
  }

  return (
    <GamesPage
      activeFilter={activeFilter}
      currentPage={pagination.values.page}
      games={games}
      managedGames={managedGames}
      joinableGames={joinableData?.games}
      assumedUserName={assumedUser?.name}
      isPending={isPending}
      numberOfRows={pagination.values.size}
      totalEntries={totalGames}
      onFilterChange={handleFilterChange}
      onGameClick={handleGameClick}
      onPageChange={handlePageChange}
      onWinningsClick={handleWinningsClick}
    />
  )
}
